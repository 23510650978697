import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './baseQuery';

export const videoApi = createApi({
  baseQuery: customBaseQuery,
  reducerPath: 'videoApi',
  tagTypes: ['Video'],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: (params) => ({
        url: '/training_categories',
        method: 'GET',
        params: params,
      }),
    }),
    getCategoryDetail: builder.query({
      query: (categoryId) => ({
        url: `/training_categories/${categoryId}`,
        method: 'GET',
      })
    }),
    getVideosFromCategory: builder.query({
      query: (params) => ({
        url: `training_categories/${params.categoryName}/trainings`,
        method: 'GET',
        params: params,
      }),
    }),
    getVideoDetail: builder.query({
      query: (params) => ({
        url: `/training_categories/${params.categoryName}/trainings/${params.videoName}`,
        method: 'GET',
      })
    }),
    createTrainingProgress: builder.mutation({
      query: (videoId) => ({
        url: '/training_progresses',
        method: 'POST',
        body: {
          training_progress: {
            training_id: videoId
          }
        }
      })
    }),
    updateTrainingProgress: builder.mutation({
      query: (params) => ({
        url: `/training_progresses/${params.training_process_id}`,
        method: 'PUT',
        body: {
          training_progress: params.training_progress,
          is_required_training: params.is_required_training,
          year: params.year,
          month: params.month,
        }
      })
    }),
    getTrainingTest: builder.query({
      query: (trainingId) => ({
        url: `trainings/${trainingId}/training_tests`,
        method: 'GET',
      }),
    }),
    checkAnswer: builder.mutation({
      query: (params) => ({
        url: `trainings/${params.trainingId}/training_tests/submit`,
        method: 'POST',
        body: {
          answer_ids: params.answers,
          training_progress_id: params.trainingProgressId
        }
      }),
    }),
    getCompletedTrainingProgresses: builder.query({
      query: () => ({
        url: '/completed_training_progresses',
        method: 'GET',
      })
    }),
    getRequiredTrainingProgressesByUser: builder.query({
      query: ({ params, id}) => ({
        url: `/users/${id}/required_training_progresses`,
        method: 'GET',
        params
      }),
    }),
    getVideo: builder.query({
      query: (params) => ({
        url: '/trainings',
        method: 'GET',
        params: params
      }),
    }),
    getAllRequiredTrainingProgressesByUser: builder.query({
      query: (params) => ({
        url: '/required_training_progresses',
        method: 'GET',
        params
      }),
    }),
    getTabCompanies: builder.query({
      query: () => ({
        url: '/tab_companies',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  usePrefetch,
  useGetCategoriesQuery,
  useGetCategoryDetailQuery,
  useGetVideosFromCategoryQuery,
  useGetVideoDetailQuery,
  useCreateTrainingProgressMutation,
  useUpdateTrainingProgressMutation,
  useGetTrainingTestQuery,
  useCheckAnswerMutation,
  useGetCompletedTrainingProgressesQuery,
  useGetAllRequiredTrainingProgressesByUserQuery,
  useGetRequiredTrainingProgressesByUserQuery,
  useGetVideoQuery,
  useGetTabCompaniesQuery
} = videoApi;
