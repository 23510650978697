import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { responsive } from 'themes/style';

const dialogFadeIn = keyframes`
  from 0% to {
    opacity: 0
  }
  100% {
    opacity: 1;
    transform: translateY(20px)
  }
`

const modalFadeIn = keyframes`
  from 0% to {
    opacity: 0
  }
  100% {
    opacity: 1;
  }
`

const StyledOverlay = styled.div((props) => ({
  display: props.show ? 'block' : 'none',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  opacity: 0,
  overflow: 'auto',
  zIndex: 2000,
  height: '100%',
  backgroundColor: 'var(--black-70-color)',
  animationDelay: 0,
  animationDuration: '0.3s',
  animationName: modalFadeIn,
  animationFillMode: 'forwards',
  animationIterationCount: 1,
  // animationTimingFunction: 'ease-in',
}));

const StyledOverlayDialog = styled.div(() => ({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'auto',
  animation: dialogFadeIn
}));

const StyledDialog = styled.div((props) => ({
  position: 'relative',
  background: 'var(--white-color)',
  borderRadius: 20,
  boxSizing: 'border-box',
  width: props.width || '40%',
  maxWidth: props.maxWidth,
  top: props.top || 230,
  opacity: 1,
  margin: props.margin || '0 auto',
  padding: props.padding || '40px 80px',
  animationDelay: 0,
  animationDuration: '0.3s',
  animationName: dialogFadeIn,
  animationFillMode: 'forwards',
  [`${responsive('max', 'smartphone')}`]: {
    top: props.top || 200,
    width: 'auto',
    height: 'auto',
    minHeight: '200px',
    '& h2': {
      fontSize: '15px'
    },
  }
}));

const StyledBoxButton = styled.div((props) => ({
  width: '100%',
  marginTop: 33,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  '& button': {
    minWidth: '240px',
    width: 'auto',
    padding: '18px 74px',
    margin: '0px 12px',
  },
  [`${responsive('max', 'smartphone')}`]: {
    '& button': {
      minWidth: '120px',
      fontSize: 16,
      padding: '0',
      margin: '0px 12px',
    }
  }
}));

export {
  StyledOverlay,
  StyledOverlayDialog,
  StyledDialog,
  StyledBoxButton
};
